export const ENDPOINTS = {
  //authentication
  LOGIN: "/api/login",
  FORGOT_PASSWORD: "/api/forgot-password",
  RESET_PASSWORD: "/api/reset-password",
  SOCIAL_LOGIN: "/api/social-login",
  LOGOUT: "/api/logout",

  //customers
  CUSTOMER: "/api/customer",
  CUSTOMER_CHANGE_PASSWORD: "/api/customer/change-password",
  // CUSTOMER_BOOKING_LIST: "/api/customer/appointments",
  CUSTOMER_BOOKING_UPCOMING_LIST: "/api/customer/upcoming/appointments",
  CUSTOMER_BOOKING_PAST_LIST: "/api/customer/past/appointments",
  CUSTOMER_BOOKING_LIST_DETAILS: "/api/customer/appointments/details",
  CUSTOMER_CLASSES: "/api/customer/classes-list",
  CUSTOMER_CLASSES_DETAILS: "/api/customer/classes-detail",
  CUSTOMER_PAYMENT_HISTORY: "/api/customer/payment/new/history",
  GET_TESTMONIALS: "/api/approved/review",
  CUSTOMER_BOOK_SLOT: "/api/customer/book/slot",
  CUSTOMER_GET_SLOT: "/api/customer/slot",
  CUSTOMER_STORE_BOOKING: "/api/customer/booking/store",
  CUSTOMER_CHECK_CLASS_AVAILABLE: "/api/customer/checkclassAvailable",
  GET_COURSE_SLOT_DATA: "/api/customer/class/slot",
  CUSTOMER_PAYMENT_STATUS_UPDATE: "/api/customer/payment-status/update",
  BOOKING_GET_SLOT_AFTER_PAYMENT: "/api/customer/slot/after_payment",
  CLASS_GET_SLOT_AFTER_PAYMENT: "/api/customer/after_payment/class/slot",
  CLASS_REMAINING_PAYMENT_DETAIL_API: "/api/customer/class/final/slot",
  CLASS_REMAINING_PAYMENT_API: "/api/customer/class/final/payment",

  //healers
  HEALER: "/api/healer",
  GET_SITEMAP_HEALERS: "/api/sitemap/healer",
  GET_SITEMAP_COURSES: "api/sitemap/courses",
  GET_SITEMAP_BLOGS: "/api/sitemap/blogs",
  HEALER_CHANGE_PASSWORD: "/api/healer/change-password",
  HEALER_UPDATE_MODALITIES: "/api/healer/modalities",
  HEALER_UPDATE_SYMPTOMS: "/api/healer/symptoms",
  HEALER_UPDATE_BOOKING_TYPES_AND_LANGUAGES:
    "/api/healer/types-and-languages/update",
  HEALER_UPDATE_BOOKING_TYPES: "/api/healer/booking-types",
  HEALER_UPDATE_LANGUAGES: "/api/healer/languages",
  HEALER_BANK_DETAILS: "/api/healer/bank-details",
  HEALER_BANK_DETAILS_UPDATE: "/api/healer/bank-details/update",
  HEALER_BANK_DETAILS_DELETE: "/api/healer/bank-details/destroy",
  HEALER_BANK_DETAILS_CREATE: "/api/healer/bank-details",
  HEALER_HOLIDAY_MARK: "/api/healer/holiday-mark",
  HEALER_HOLIDAYS: "/api/healer/holiday",
  DELETE_HEALER_HOLIDAY: "api/healer/holiday/delete",
  GET_HEALER_APPOINTMENT: "/api/healer/appointments/list",
  GET_BUSINESS_DETAILS: "/api/healer/bussiness-details",
  ADD_UPDATE_BUSINESS_DETAILS: "/api/healer/bussiness-details/update",
  GET_HEALER_EXPERIENCE: "/api/healer/experiences/list",
  HEALER_EDUCATION_LIST: "/api/healer/education/list",
  HEALER_ADD_EDUCATION: "/api/healer/education/store",
  HEALER_EDIT_EDUCATION: "/api/healer/education/edit",
  HEALER_EDUCATION_UPDATE: "/api/healer/education/update",
  HEALER_EDUCATION_DELETE: "/api/healer/education/delete",
  HEALER_EXPERIENCE_UPDATE: "/api/healer/experiences/update",
  HEALER_EXPERIENCE_EDIT: "/api/healer/experience/edit",
  HEALER_ADD_CERTIFICATION: "/api/healer/certification/store",
  HEALER_CERTIFICATION_LIST: "/api/healer/certification/list",
  HEALER_EDIT_CERTIFICATION: "/api/healer/certification/edit",
  HEALER_UPDATE_CERTIFICATION: "/api/healer/certification/update",
  HEALER_CERTIFICATION_DELETE: "/api/healer/certification/delete",
  HEALER_ADD_AWARD: "/api/healer/award/store",
  HEALER_AWARD_LIST: "/api/healer/award/list",
  HEALER_EDIT_AWARD: "/api/healer/award/edit",
  HEALER_UPDATE_AWARD: "/api}/healer/award/update",
  HEALER_AWARD_DELETE: "/api/healer/award/delete",
  HEALER_ADD_CLASS: "/api/healer/create-class",
  GET_HEALER_CLASSES_LIST: "/api/healer/classes/list",
  GET_HEALER_CLASSES_UPCOMING_LIST: "/api/healer/classes/upcoming/list",
  GET_HEALER_CLASSES_PAST_LIST: "/api/healer/classes/past/list",
  GET_HEALER_CLASSES_DETAILS: "/api/healer/classes-details",
  HEALER_CLASSES_STATUS_UPDATE: "/api/healer/class-status-update",
  HEALER_APPOINTMENT_STATUS_UPDATE: "/api/healer/appointment/status/update",
  GET_CLASSES_DETAILS: "/api/healer/class-edit",
  HEALER_UPDATE_CLASS: "/api/healer/class-update",
  GET_CATEGORY_TYPE: "/api/category-type",
  HEALER_CLASS_DELETE: "/api/healer/class-delete",
  HEALER_DOWNLOAD_FILE: "/api/healer/download-file",
  HEALER_TIME_AVAILABILITY: "/api/healer/timeAvailability",
  HEALER_UPDATE_TIME_AVAILABILITY: "/api/healer/timeAvailability/update",
  HEALER_UPDATE_INTERVAL: "/api/healer/interval/update",
  HEALER_UPDATE_BOOKING_INTERVAL: "/api/healer/mintimeBeforeBooking/update",
  HEALER_DOWNLOAD_INVOICE: "/api/healer/session/invoice",

  GET_HEALERS: "/api/list/healers",
  GET_HEALER_DETAILS: "/api/healer/details",
  GET_HEALER_COURSES: "/api/courses/list",
  GET_HEALER_COURSES_DETAILS: "/api/course/details",
  GET_BOOKING_TYPES: "/api/booking-types",
  GET_TYPES_OF_BOOKING: "/api/service-types",
  ADD_HEALER_EXPERIENCE: "/api/healer/experiences/store",
  DELETE_HEALER_EXPERIENCE: "/api/healer/experience/delete",
  ADD_HEALER_APPOINTMENT: "/api/healer/appointments/store",
  EDIT_HEALER_APPOINTMENT: "/api/healer/appointments/edit",
  UPDATE_HEALER_APPOINTMENT: "/api/healer/appointments/update",
  DELETE_HEALER_APPOINTMENT: "/api/healer/appointment/delete",
  GET_MODALITIES: "/api/modalities",
  GET_SYMPTOMS: "/api/symptoms",
  GET_COUNTRIES: "/api/countries",
  GET_STATES: "/api/states",
  GET_CITIES: "/api/cities",
  GET_LANGUAGES: "/api/languages",
  REVIEW: "/api/review",
  EDUCATION_TYPES: "/api/education-type",
  SUBSCRIPTION_PLANS: "/api/healer/subscription/list",
  HEALER_SUBSCRIBE_PLAN: "/api/healer/subscribe",
  GET_SUBSCRIPTION: "/api/subscription",
  UPDATE_SUBSCRIPTION_PAYMENT: "/api/subscription/payment",
  CONTACT_US: "/api/contact/store",
  PAYMENT_CHARGES: "/api/payment/fee",
  // HEALER_BOOKING_HISTORY: "/api/healer/booking/history",
  HEALER_BOOKING_PAST_HISTORY: "/api/healer/booking/past/history",
  HEALER_BOOKING_UPCOMING_HISTORY: "/api/healer/booking/upcoming/history",
  HEALER_PAYMENT_STATUS: "/api/healer/payment/new/history",
  GET_SLIDER_INFO: "/api/blogs/list",
  GET_BLOG_DETAIL: "/api/blog/details",
  HEALER_BOOKING_DETAIL: "/api/healer/booking/history/details",
  HEALER_DASHBOARD_DETAIL: "/api/healer/dashboard",
  HEALER_UPCOMING_APPOINTMENT: "/api/healer/upcoming/appointment",
  HEALER_UPCOMING_APPLICANTS: "/api/healer/upcoming/applicants",
  HEALER_APPLICANT_DETAIL: "/api/healer/applicant-details",
  PAYMENT_REMINDER: "/api/payment/reminder",
  HEALER_ALL_PAYMENT_REMINDER: "/api/payment/reminder/all",
  HEALER_SALES_OVERVIEW: "/api/healer/dashboard/sales/overview",
  HEALER_APPOINTMENT_DATA: "/api/healer/appointments",
  HEALER_AVAILABLE_SLOTS: "/api/healer/slots/availableSlots",
  HEALER_AVAILABLE_DATES: "/api/healer/slots/availableDate",
  HEALER_SEND_LINK: "/api/healer/appointment/send/link",
  HEALER_SUBSCRIPTION_NOTICE: "/api/healer/subscription/notice",
  HEALER_SUBSCRIPTIION_RENEW: "/api/healer/subscription/renew",
  HEALER_ALL_REVIEWS: "/api/healer/review",
  GET_CUSTOMER_REVIEW: "/api/review/details",
  HEALER_PAYMENT_INVOICE: "/api/session/invoice",
  // HEALER_CLASS_LINK: "/api/healer/class/send/link",
  HEALER_LESSON_SEND_LINK: "/api/healer/lesson/send/link ",
  PERTICULAR_HEALER_COURSES: "/api/healer/courses",
  REVIEW_REMINDER: "/api/healer/booking/review/reminder",
  PRIVACY_POLICY: "/api/privacy-policy",
  TERMS_AND_CONDITIONS: "/api/terms-conditions",
  META_INFORMATION: "/api",
  HEALER_SUBSCRIPTION_PAYMENT: "api/healer/subscription/payment/history",
  HEALERSLOTS_METADATA: "api/seo/slot/page",
  HEALER_IMAGES_STORE: "api/healer/images/store",
  HEALER_IMAGES_DELETE: "api/healer/images/delete",
  HEALER_TIMEZONE: "api/healer/time-zone/update",
  MODALITY_SEARCH: "api/modality/search",
  SYMPTOMS_SEARCH: "api/symptoms/search",
  ADD_HEALER_NOTES: "api/healer/notes/store",
  HEALER_NOTES_LIST: "api/healer/notes/list",
  HEALER_NOTES_EDIT: "api/healer/notes/edit",
  HEALER_NOTES_UPDATE: "api/healer/notes/update",
  HEALER_NOTES_DELETE: "api/healer/notes/delete",
  HEALER_STRIPE_CUSTOMER_ID_STORE: "api/healer/stripe/customer/store",
  HEALER_SUBSCRIPTION_CANCEL: "api/healer/subscription/cancel",
  HEALER_TIME_UNAVAILABILITY: "api/healer/timeUnAvailability/update",
  DELETE_HEALER_TIME_UNAVAILABILITY: "api/healer/timeUnAvailability/delete",
  EXIST_USER_UPDATE_KEY: "api/healer/exist_user/update",
};
