import { api } from "@/lib/axios/axiosConfigs";
import { defineCancelApiObject } from "@/lib/axios/axiosUtils";
import { ENDPOINTS } from "@/constants/endpoints";

export const authServices = {
    signin: async function (params, cancel = false) {
        const response = await api.request({
            url: ENDPOINTS.LOGIN,
            method: "POST",
            data: params,
            withCredentials: false,
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.signin.name].handleRequestCancellation().signal : undefined,
        });

        // returning the data
        return response.data;
    },
    socialSignIn: async function (params, cancel = false) {
        const response = await api.request({
            url: ENDPOINTS.SOCIAL_LOGIN,
            method: "POST",
            data: params,
            withCredentials: false,
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.socialSignIn.name].handleRequestCancellation().signal : undefined,
        });

        // returning the data
        return response.data;
    },
    forgotPassword: async function (params, cancel = false) {
        const response = await api.request({
            url: `${ENDPOINTS.FORGOT_PASSWORD}`,
            method: "POST",
            data: params,
            withCredentials: false,
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.forgotPassword.name].handleRequestCancellation().signal : undefined,
        });

        // returning the data
        return response.data;
    },
    resetPassword: async function (params, cancel = false) {
        const response = await api.request({
            url: `${ENDPOINTS.RESET_PASSWORD}`,
            method: "POST",
            data: params,
            withCredentials: false,
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.resetPassword.name].handleRequestCancellation().signal : undefined,
        });

        // returning the data
        return response.data;
    },
    signout: async function (params, cancel = false) {
        const response = await api.request({
            url: `${ENDPOINTS.LOGOUT}`,
            method: "POST",
            data: params,
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.signout.name].handleRequestCancellation().signal : undefined,
        });

        // returning the data
        return response.data;
    },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(authServices);