import Link from "next/link";
import Image from "next/image";
import { Modal } from "react-bootstrap";
import React, { useState } from 'react';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { signOut } from "next-auth/react";
import { useRouter } from 'next/router';
import { authServices } from "@/services/authServices";
import { setSession, setUserData } from "@/redux/features/userSlice";
import { clearBrowserStorage } from "@/helper/common";
import styles from '@/styles/Footer.module.css';

export default function Footer() {
  const [smShow, setSmShow] = useState(false);
  const dispatch = useDispatch();
  const { pathname } = useRouter();
  const { session, userData } = useSelector((state) => state.user);
  const { selectedImage } = useSelector((state) => state.healerProfile);
  const [userType, setUserType] = useState(null);

  const logOut = async () => {
    const res = await authServices.signout(session);
    const callbackUrl =
      (userType || window.location.pathname.includes("healer")) == "Healer"
        ? "/auth/healer/signin"
        : "/auth/customer/signin";
    if (res.status) {
      clearBrowserStorage();
      dispatch(setSession([]));
      dispatch(setUserData([]));
      signOut({ callbackUrl });
    } else {
      toast.error(res.message, {
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    if (userData?.roles?.length) {
      if (userData.roles[0].name == "Healer") {
        setUserType("Healer");
      } else {
        setUserType("Customer");
      }
    }
  }, [userData]);

  return (
    <>
      <footer className={styles["footer"] + " " + `footer-top`}>
        <div className={styles["footer-section"]}>
          <Container>
            <Row>
              <Col md={4} className={styles["footer-content"]}>
                <div className={styles["logo"]}>
                  <Link href="/" aria-label="HomePage">
                    <Image
                      src="/images/logo.svg"
                      height={116}
                      width={180}
                      alt="ZENKI"
                      priority={true}
                    />
                  </Link>
                  <p>Simplify Your Healing Journey</p>
                  <ul>
                    <li>
                      <Link
                        href="https://www.facebook.com/ZenkifyWorldwide"
                        target="blank"
                        aria-label="Facebook"
                      >
                        <i className="ri-facebook-fill"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://www.instagram.com/zenkify_worldwide/"
                        target="blank"
                        aria-label="Instagram"
                      >
                        <i className="ri-instagram-fill"></i>
                      </Link>
                    </li>
                    {/* <li><Link href="#"><i className="ri-linkedin-fill"></i></Link></li>
                    <li><Link href="#"><i className="ri-youtube-fill"></i></Link></li> */}
                    <li><Link href="https://twitter.com/Zenkify" target="blank" aria-label="Twitter"><i className="ri-twitter-x-line"></i></Link></li>
                  </ul>
                </div>
              </Col>
              <Col md={1} className="d-lg-block d-none"></Col>
              <Col lg={3} md={5} className={styles["footer-contact"]}>
                <h4 className={styles["heading-footer"]}>Contact Info</h4>
                <ul>
                  {/* <li><i className="ri-map-pin-line"></i> <Link href={''}>4th MalviyLink Nagar St 4th Jaipur (Raj.)</Link></li>
                  <li><i className="ri-phone-line"></i><Link href="tel:(+91) 12345 00000">(+91) 12345 00000</Link></li>
                  <li><i className="ri-whatsapp-line"></i><Link href="(+91) 12345 00000">(+91) 12345 00000</Link></li> */}
                  <li>
                    <i className="ri-mail-line"></i>
                    <Link href="mailto:hello@zenkify.com">
                      hello@zenkify.com
                    </Link>
                  </li>
                  <li>
                    <i className="ri-phone-line"></i>
                    <Link href="tel:+1(208) 407-9559">
                    +1(208) 407-9559
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col md={1} className="d-lg-block d-none"></Col>
              <Col md={3} className={styles["quick-link"]}>
                <h4 className={styles["heading-footer"]}>Quick Links</h4>
                <ul>
                  <li>
                    <Link href={"/contact-us"}>Contact Us</Link>
                  </li>
                  <li>
                    <Link href={"/about-us"}>About Us</Link>
                  </li>
                  <li>
                    <Link href="/blogs">Blogs</Link>
                  </li>
                  <li>
                    <Link href="/terms-and-conditions">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link href="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={styles["footer-bottom"]}>
          <p className={styles["copyright"]}>
            Copyright © 2024 <Link href="/">ZENKIFY</Link>. All Rights Reserved
            Design &amp; Developed by{" "}
            <Link href="https://www.kadamtech.com" target="_blank">Kadam Technologies pvt. ltd</Link>
          </p>
        </div>
      </footer>
      <div className={styles["footer-mb"] + " " + `fb-margin`}></div>
      <div className={styles["m-footer"] + ' mobile-hide-footer'}>
        {session?.id && pathname.split("/")[1] === 'healer' ?
          <ul>
            <li className={styles[pathname == "/healer/dashboard" ? 'active' : ""]}>
              <Link href="/healer/dashboard">
                <Image src="/images/m-footer/home.svg" height={22} width={22} alt="Dashboard" priority={true} />
                <span>Dashboard</span>
              </Link>
            </li>
            <li className={styles[pathname == "/healer/time-availability" ? 'active' : ""]}>
              <Link href="/healer/time-availability">
                <Image src="/images/dashboard-Icon/time-availability.svg" height={22} width={22} alt="Time" priority={true} />
                <span>Time</span>
              </Link>
            </li>
            <li className={styles[pathname == "/healer/subcription-plans" ? 'active' : ""]}>
              <Link href="/healer/subcription-plans">
                <Image src="/images/dashboard-Icon/subscription-plan.svg" height={22} width={22} alt="Subscription" priority={true} />
                <span>Subscription</span>
              </Link>
            </li>
            <li className={styles[pathname == "/healer/booking-history" ? 'active' : ""]}>
              <Link href="/healer/booking-history">
                <Image src="/images/dashboard-Icon/booking-history.svg" height={22} width={22} alt="Booking" priority={true} />
                <span>Booking</span>
              </Link>
            </li>
            <li className={styles[pathname == "/healer/account" ? 'active' : ""]}>
              {session?.id && userType == "Customer"
                ?
                <Link href="/customer"><Image
                  width={60}
                  height={60}
                  src={
                    selectedImage
                      ? URL.createObjectURL(selectedImage)
                      : userData?.image ?? "/images/m-footer/account.svg"
                  }
                  alt="profile image"
                ></Image><span>Account</span></Link>
                :
                !session?.id && !session.status ?
                  <button
                    onClick={() => {
                      setSmShow(true);
                    }}
                    className={styles['accountbtn']}
                    data-bs-toggle="modal"
                    data-bs-target="#signpop-up"
                  >
                    <Image
                      width={60}
                      height={60}
                      src={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : userData?.image ?? "/images/m-footer/account.svg"
                      }
                      alt="profile image"
                    ></Image>
                    <span>Account</span>
                  </button>
                  :
                  <Link href="/healer/account"><Image
                    width={60}
                    height={60}
                    src={
                      selectedImage
                        ? URL.createObjectURL(selectedImage)
                        : userData?.image ?? "/images/m-footer/account.svg"
                    }
                    alt="profile image"
                  ></Image><span>Account</span></Link>
              }
            </li>
          </ul>
          :
          <ul>
            <li className={styles[pathname == "/" ? 'active' : ""]}>
              <Link href="/">
                <Image src="/images/m-footer/home.svg" height={22} width={22} alt="homepage" priority={true} />
                <span>Home</span>
              </Link>
            </li>
            <li className={styles[pathname == "/services" ? 'active' : ""]}>
              <Link href="/services">
                <Image src="/images/m-footer/services.svg" height={22} width={22} alt="servicepage" priority={true} />
                <span>Services</span>
              </Link>
            </li>
            <li className={styles[pathname == "/healers" ? 'active' : ""]}>
              <Link href="/healers">
                <Image src="/images/m-footer/healer.svg" height={22} width={22} alt="helerpage" priority={true} />
                <span>Healer</span>
              </Link>
            </li>
            <li className={styles[pathname == "/courses" ? 'active' : ""]}>
              <Link href="/courses">
                <Image src="/images/m-footer/classes.svg" height={22} width={22} alt="class" priority={true} />
                <span>Classes</span>
              </Link>
            </li>
            <li>
              {session?.id && userType == "Customer"
                ?
                <Link href="/customer"><Image
                  width={60}
                  height={60}
                  src={
                    selectedImage
                      ? URL.createObjectURL(selectedImage)
                      : userData?.image ?? "/images/m-footer/account.svg"
                  }
                  alt="profile image"
                ></Image><span>Account</span></Link>
                :
                !session?.id && !session?.status ?
                  <button
                    onClick={() => {
                      setSmShow(true);
                    }}
                    className={styles['accountbtn']}
                    data-bs-toggle="modal"
                    data-bs-target="#signpop-up"
                  >
                    <Image
                      width={60}
                      height={60}
                      src={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : userData?.image ?? "/images/m-footer/account.svg"
                      }
                      alt="profile image"
                    ></Image>
                    <span>Account</span>
                  </button>
                  :
                  <Link href="/healer/dashboard"><Image
                    width={60}
                    height={60}
                    src={
                      selectedImage
                        ? URL.createObjectURL(selectedImage)
                        : userData?.image ?? "/images/m-footer/account.svg"
                    }
                    alt="profile image"
                  ></Image><span>Account</span></Link>
              }
            </li>
          </ul>
        }
        <Modal
          className={styles['modal-signup']}
          id="signpop-up"
          show={smShow}
          onHide={() => setSmShow(false)}
          size="sm"
          centered
        >
          <Modal.Header className={styles['modal-header']}>
            <button
              type="button"
              className={styles['btn-close']}
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setSmShow(false)}
            >
              <i className="ri-close-fill"></i>
            </button>
          </Modal.Header>
          <Modal.Body
            className={styles['modal-body']}
          >
            <div className={styles['sign-card']} >

              {!session?.id ? (
                <div className={styles["innerbox"]}>

                  <h4>Log In / Sign Up</h4>
                  <ul>
                    <li>
                      <Link href="/auth/customer/social/signin" onClick={() => {
                        setSmShow(false);
                      }}>
                        Customer Log In
                      </Link>
                    </li>
                    <li>
                      <Link href="/auth/healer/social/signin" onClick={() => {
                        setSmShow(false);
                      }}>
                        Healer Log In
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : (
                <div
                  className={styles["innerbox"]}
                >
                  <ul>
                    <li>
                      <Link
                        href={
                          "/healer/dashboard"
                        }
                        onClick={() => {
                          setSmShow(false);
                        }}
                      >
                        <i className="ri-user-3-line"></i>Profile
                      </Link>
                    </li>
                    <li>
                      <Button type="button" onClick={() => { logOut(); setSmShow(false); }} >
                        <i className="ri-logout-box-r-line"></i>{" "}
                        <span>Logout</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}