import { useEffect, useState } from "react";
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";
import { FormText, Container, Row, Col, Button } from "react-bootstrap";
import Image from "next/image";
import { toast } from "react-toastify";
import { signOut } from "next-auth/react";
import useStorage from "@/hooks/storage";
import { authServices } from "@/services/authServices";
import { setSession, setUserData } from "@/redux/features/userSlice";
import { clearBrowserStorage } from "@/helper/common";
import styles from "@/styles/Header.module.css";
import { useRouter } from 'next/router';
//import Card from "../pages/api/data.json";

export default function Header() {
  const dispatch = useDispatch();
  const { pathname, push } = useRouter();
  const { session, userData } = useSelector((state) => state.user);
  const { selectedImage } = useSelector((state) => state.healerProfile);
  const [localData, setLocalData] = useStorage("user", "localStorage");
  const [userType, setUserType] = useState(null);
  const [card, setCard] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const heading = card != null ? Object?.keys(card)?.filter((i) => i == pathname) : "";

  useEffect(() => {
    fetchData();   
  }, []);

  const fetchData = async () => {
    const response = await fetch('/api/get-pages-name')
    const data = await response.json();
    setCard(data);
  }

  const logOut = async () => {
    const res = await authServices.signout(session);
    const callbackUrl =
      (userType || window.location.pathname.includes("healer")) == "Healer"
        ? "/auth/healer/signin"
        : "/auth/customer/signin";
    if (res.status) {
      clearBrowserStorage();
      dispatch(setSession([]));
      dispatch(setUserData([]));
      signOut({ callbackUrl });
    } else {
      toast.error(res.message, {
        autoClose: 5000,
      });
    }
  };

  const goBack = () => {
    if (pathname == "/customer/profile" && window.innerWidth < 991) {
      push("/customer");
    } else {
      window.history.back();
    }
  };

  useEffect(() => {
    if (
      localData &&
      localData != null &&
      localData != "null" &&
      Object.keys(localData).length
    ) {
      const storageData = JSON.parse(localData);
      dispatch(setSession(storageData.session));
      dispatch(setUserData(storageData.user));
    }
  }, [localData, dispatch]);

  useEffect(() => {
    if (userData?.roles?.length) {
      if (userData.roles[0].name == "Healer") {
        setUserType("Healer");
      } else {
        setUserType("Customer");
      }
    }
  }, [userData]);

  return (
    <>
      <header className={styles["main-header"]}>
        <Container className={styles["container"]}>
          <Row>
            <Col md={2}>
              <div className={styles["logo-box"] + " " + `mainlogo-box`}>
                <div className={styles["logo"]}>
                  <Link href="/" aria-label="HomePageHeader">
                    <Image
                      src="/images/logo.svg"
                      alt="Healing Services"
                      height={93}
                      width={60}
                      priority={true}
                    />
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={4} md={3} className="align-self-center">
              <div className={styles["header-search-engine"]}>
                <div className={styles["container-search-engine"]}>
                  <span className={styles["caption-heading"]}>
                    <i className="ri-user-3-line"></i> What do you want to
                    learn?
                  </span>
                  <div className={styles["fields"]}>
                    <div className={styles["input-wrapper"]}>
                      <div className={styles["field-wrapper"]}>
                        <i className="ri-book-line"></i>
                        <FormText
                          type="text"
                          className={styles["input-search"]}
                          placeholder="Choose Link subject"
                        />
                      </div>
                    </div>
                    <div className={styles["input-wrapper"]}>
                      <div className={styles["field-wrapper"]}>
                        <i className="ri-map-pin-line"></i>
                        <FormText
                          type="text"
                          className={styles["input-search"]}
                          placeholder="Choose Link subject"
                        />
                      </div>
                    </div>
                  </div>
                  <Button className={styles["search-button"]}>
                    <i className="ri-search-2-line"></i>
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={8} md={9} className="ms-auto">
              <div className={styles["navigation-bar"]}>
                <ul>
                  <li>
                    <Link href="/services">Healing Modalities </Link>
                  </li>
                  <li>
                    <Link href="/healers">Healers Listing</Link>
                  </li>
                  <li>
                    <Link href="/courses">Courses Offered</Link>
                  </li>
                  <li>
                    <Link className={styles["join-healer-btn"]} href="/join-as-a-healer">Join as a Healer</Link>
                  </li>
                </ul>
                {!session?.id ? (
                  <div className={styles["dropdown-login"]}>
                    <span
                      className={styles["dropdown-btn"] + " " + "theme-btn"}
                    >
                      Log In / Sign Up<i className="ri-user-line"></i>
                    </span>
                    <ul className={styles["dropdown-content"]}>
                      <li>
                        <Link href="/auth/customer/social/signin">
                          Customer Log In
                        </Link>
                      </li>
                      <li>
                        <Link href="/auth/healer/social/signin">
                          Healer Log In
                        </Link>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div
                    className={
                      styles["dropdown-login"] + " " + styles["user-dropdown"]
                    }
                  >
                    <span
                      className={
                        styles["dropdown-btn"] + " " + styles["user-icon"]
                      }
                    >
                      <Image
                        width={60}
                        height={60}
                        src={
                          selectedImage
                            ? URL.createObjectURL(selectedImage)
                            : userData?.image ?? "/images/dummy-user.svg"
                        }
                        alt="profile image"
                      ></Image>
                    </span>
                    <ul className={styles["dropdown-content"]}>
                      <li>
                        <Link
                          href={
                            userType == "Healer" ||
                              window.location.pathname.includes("healer/")
                              ? "/healer/my-account"
                              : "/customer/profile"
                          }
                        >
                          <i className="ri-user-3-line"></i>Profile
                        </Link>
                      </li>
                      <li>
                        <Button type="button" onClick={() => logOut()}>
                          <i className="ri-logout-box-r-line"></i>{" "}
                          <span>Logout</span>
                        </Button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className={styles["mobile-menu"] + " " + `mobilemenu`}>
                <Link className={styles["join-healer-btn"]} href="/join-as-a-healer">Join as a Healer</Link>
                <div className={styles["mobile-toggle"]} onClick={() => setShowModal(true)}>
                  <i className="ri-menu-line"></i>
                </div>
                <div className={showModal ? styles["mobile-navigation"] + " " + styles["show"] : styles["mobile-navigation"]}>
                  <div className={styles["menuclose"]} onClick={() => setShowModal(false)}>
                    <i className="ri-close-fill"></i>
                  </div>
                  <h4>Menu</h4>
                  <ul>
                    <li><Link onClick={() => setShowModal(false)} href="/">Home</Link></li>
                    <li><Link onClick={() => setShowModal(false)} href={'/about-us'}>About US</Link></li>
                    <li><Link onClick={() => setShowModal(false)} href={'/services'}>Healing Modalities</Link></li>
                    <li><Link onClick={() => setShowModal(false)} href={'/healers'}>Healers Listing</Link></li>
                    <li><Link onClick={() => setShowModal(false)} href={'/courses'}>Courses Offered</Link></li>
                    <li><Link onClick={() => setShowModal(false)} href="/blogs">Blogs</Link></li>
                    <li><Link onClick={() => setShowModal(false)} href="/terms-and-conditions">Terms & Conditions</Link></li>
                    <li><Link onClick={() => setShowModal(false)} href="/privacy-policy">Privacy Policy</Link></li>
                    <li><Link onClick={() => setShowModal(false)} href={'/contact-us'}>Contact Us</Link></li>
                  </ul>
                </div>
                <div className={showModal ? styles["backgroundOverlay"] + " " + `headermainoverlay` + " " + styles["show"] : styles["backgroundOverlay"]} onClick={() => setShowModal(false)}></div>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      <div className={styles["header-backhistory"] + " " + 'headerbackbta'}>
        <div className={styles["inner-box"]}>
          <div className={styles["backbutton"]} onClick={goBack}>
            <i className="ri-arrow-left-s-line"></i>
          </div>
          <div className={styles["pagename-heading"]}>
            <h6>{card != null ? card[heading[0]]: ""}</h6>
          </div>
        </div>
      </div>
    </>
  );
}
