export const maskNumber = input => {
    const numericPart = input.replace(/\D/g, '');
    const lastFourDigits = numericPart.slice(-4);
    const maskedPart = 'X'.repeat(Math.max(0, numericPart.length - 4)) + lastFourDigits;
    return maskedPart;
};

export const maskEmail = email => {
    const atIndex = email.indexOf('@');
    if (atIndex !== -1) {
        const maskedPart = 'x'.repeat(atIndex) + email.slice(atIndex);
        return maskedPart;
    }
    return email;
};

export const clearBrowserStorage = () => {
    if (typeof window !== "undefined") {
        localStorage.clear();
        sessionStorage.clear();
    } else {
        throw Error("Unable to clear storage, because function is called before dom.");
    }
};
